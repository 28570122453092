.preparation-container {
  margin-top: 10px;
}

.preparation-number-button-group {
  display: grid;
  grid-template-columns: 245px 245px;
  gap: 10px;
}

.report-btn {
  position: fixed;
  bottom: 24px;
  left: 88px;
}

.delete-account-button {
  color: blue;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .preparation-number-button-group {
    grid-template-columns: 100%;
  }

  .report-btn {
    left: 24px;
  }
}
