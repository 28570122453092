.modal-container {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;

  .plan-container {
    display: grid;
    gap: 14px;
    padding: 20px;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    font-size: 14px;
    text-align: start;
    color: var(--color-surface-4);
    width: 100%;

    .plan-name {
      font-size: 32px;
    }

    .plan-price {
      font-size: 20px;
    }

    .subitem-container {
      list-style-type: disc;
      padding-left: 30px;
    }

    .item-disabled {
      color: var(--color-content-disable);
    }
  }
}

.bold {
  font-weight: bold;
}

.header-info {
  text-align: center;
  padding-bottom: 20px;
  width: calc(100% - 25px);
  margin-top: -40px;

  .header-title {
    color: var(--color-primary);
    font-size: 32px;
    font-weight: bold;
  }
}

.plan-card-container {
  display: flex;
  flex-direction: column;
  outline: 2px solid var(--color-surface-2);
  border-top: 16px solid #fff;
  border-radius: 16px;
  background: #fff;
  padding: 16px 0;

  .plan-card-subitem-container {
    display: grid;
    gap: 8px;
    padding: 10px 20px;
    justify-content: start;
    text-align: start;
  }

  .plan-card-separator {
    height: 1px;
    margin: 10px 20px;
    background: var(--color-surface-3);
  }
}

.highlight {
  outline: 2px solid var(--color-primary);
  border-top: 16px solid var(--color-primary);
}

.channels-items-container {
  display: flex;
}

.side-by-side-content-wrapper {
  display: flex;
  gap: 5px;
  margin-right: 8px;
  align-items: center;
}

.color-primary {
  color: var(--color-primary);
}

@media screen and (max-width: 900px) {
  .modal-container {
    grid-template-columns: 100%;
    height: calc(100vh - 250px);
    overflow-y: scroll;
  }

  .plan-card-container {
    margin: 2px;
  }

  .header-title {
    font-size: 16px !important;
  }
}
