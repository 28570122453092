.whatsapp-trial-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  align-items: center;

  .free-days {
    font-size: 40px;
    font-weight: 800;
    color: #1e6bf1 !important;
  }

  .whatsapp-trial-logo {
    width: 200px;
  }

  .whatsapp-trial-btn-container {
    padding-top: 16px;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    width: 100%;
  }
}
