.modal-shell-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modal-shell-header {
  display: flex;
  justify-content: flex-end;
  height: 80px;
  width: 100%;
}
