.blip-go-introduction-section-container {
  display: grid;
  gap: 24px;
  grid-template-columns: calc(35% - 12px) calc(65% - 12px);
  text-align: start;
  color: var(--color-content-default);
}
.blip-go-introduction-container {
  margin-top: -40px;
  width: calc(100% - 25px);

  .feature-not-included-warning {
    text-align: center;
  }
}

.blip-go-introduction-section {
  padding: 16px;
  background: #fff;
  border-radius: 16px;
  height: 100%;
}

.primary-color {
  color: var(--color-primary) !important;
}

.color-surface-4 {
  color: var(--color-surface-4);
}

.intention-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  text-align: center;

  .intention-card-wrapper {
    display: flex;
    background: #fff !important;
    justify-content: center;
    align-items: center;
    padding: 8px;
    height: 145px;
    width: 100%;
    cursor: pointer;
    &:hover {
      outline: 2px solid var(--color-surface-3);
    }
  }

  .active-item {
    outline: 3px solid var(--color-primary) !important;
  }
}

.intention-action-container {
  display: flex;
  justify-content: right;
}

@media screen and (max-width: 900px) {
  .blip-go-introduction-section-container {
    grid-template-columns: 100%;
    height: calc(100vh - 250px);
    overflow-y: scroll;
  }

  .intention-card-container {
    grid-template-columns: 100%;
  }
}
