.report-btn {
  position: fixed;
  bottom: 24px;
  left: 88px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .report-btn {
    left: 24px;
  }
}
