.plan-paper-container {
    display: grid;
    grid-template-columns: 100%;
    gap: 10px;

    .plan-container {
        display: grid;
        gap: 14px;
        padding: 20px;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        font-size: 14px;
        text-align: start;
        width: 100%;

        .plan-name {
            font-size: 32px;
        }

        .plan-price {
            font-size: 20px;
        }

        .subitem-container {
            list-style-type: disc;
            padding-left: 30px;
        }
    }
}

.bold {
    font-weight: bold;
}

.bolder {
    font-weight: 800;
}

.header-plan {
    font-size: 24px;
    text-align: start;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media screen and (max-width: 900px) {
    .plan-paper-container {
        grid-template-columns: 100%;
        height: calc(100vh);

    }

    .plan-container {
        padding: 0 8px;
    }
}