.contract-information-container {
    display: grid;
    grid-template-columns: 50% 50%;
}

.contract-information {
    margin-top: 20px;
}

.contract-button {
    margin-bottom: 20px;
}

@media screen and (max-width: 900px) {
    .contract-information-container {
        grid-template-columns: 100%;
        height: calc(100vh - 250px);
    }

    .contract-button {
        margin-bottom: 20px;
    }

    .contract-information {
        margin-top: 100px;
    }
}