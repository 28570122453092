.modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 700px;

}

.modal-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 32px 40px;
}

.modal-button {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
}

