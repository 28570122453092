.collapsible {
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active,
.collapsible:hover {
  background-color: #f5f5f5;
}

.content {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f5f5f5;
}