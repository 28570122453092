@font-face {
  font-family: "Carbona";
  src: url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/OTF/Carbona-Bold.otf") format("opentype"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/TTF/Carbona-Bold.ttf") format("truetype"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/WOFF/Carbona-Bold.woff") format("woff"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/WOFF2/Carbona-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Carbona";
  src: url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/OTF/Carbona-BoldSlanted.otf") format("opentype"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/TTF/Carbona-BoldSlanted.ttf") format("truetype"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/WEB/WOFF/Carbona-BoldSlanted.woff") format("woff"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/WEB/WOFF2/Carbona-BoldSlanted.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Carbona";
  src: url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/OTF/Carbona-Light.otf") format("opentype"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/TTF/Carbona-Light.ttf") format("truetype"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/WEB/WOFF/Carbona-Light.woff") format("woff"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/WEB/WOFF2/Carbona-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Carbona";
  src: url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/OTF/Carbona-LightSlanted.otf") format("opentype"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/TTF/Carbona-LightSlanted.ttf") format("truetype"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/WEB/WOFF/Carbona-LightSlanted.woff") format("woff"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/WEB/WOFF2/Carbona-LightSlanted.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Carbona";
  src: url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/OTF/Carbona-Medium.otf") format("opentype"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/TTF/Carbona-Medium.ttf") format("truetype"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/WEB/WOFF/Carbona-Medium.woff") format("woff"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/WEB/WOFF2/Carbona-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Carbona";
  src: url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/OTF/Carbona-MediumSlanted.otf") format("opentype"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/TTF/Carbona-MediumSlanted.ttf") format("truetype"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/WEB/WOFF/Carbona-MediumSlanted.woff") format("woff"),
    url("https://hmgportalmfe.blob.core.windows.net/take/Carbona_TakeBlip/WEB/WOFF2/Carbona-MediumSlanted.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}