.activation-form-container {
  margin-top: -40px;
  width: 511px;
  padding: 20px;

  .activation-form-select {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .activation-form-title {
    color: #1E6BF1;
    text-align: left;
  }

  .activation-form-subtitle {
    text-align: left;
  }

  .activation-form-select-title {
    text-align: left;
  }

  .activation-form-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .activation-form-header {
    padding-bottom: 20px;
  }
}

.activation-form-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

@media screen and (max-width: 900px) {
  .activation-form-container {
    width: 100%;
  }
}