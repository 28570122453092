.menu-boxes__item {
  margin: 20px 30px 30px 30px;
}

.menu-boxes__imagem {
  text-align: center;
  padding-bottom: 14px;
}

.menu-boxes__buttom{
  text-align: center;
}
